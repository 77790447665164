import React from 'react'
import Layout from '../components/layout/layout'
import {graphql} from 'gatsby'

import HeroSlider from '../components/heroSlider/heroSlider'

const IndexPage = ({data}) => {
  if (data.allSanityHomePage.nodes.lenght < 1) return null
  const slider = data.allSanityHomePage.nodes[0].slider

  return (
    <Layout className='template-index' footer={false}>
      <HeroSlider slides={slider} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    allSanityHomePage {
      nodes {
        slider {
          headline
          imgAlt {
            alt
            asset {
              fluid(maxWidth: 3000) {
                src
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
          pageLink {
            ... on SanityBasicText {
              id
              slug {
                current
              }
            }
            ... on SanityProjectArchive {
              id
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`
