import React, {useState, useEffect} from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import Chevron from '../chevron/chevron'
import Swiper from 'react-id-swiper'
import classNames from 'classnames'

import './heroSlider.scss'

const HeroSlider = ({slides}) => {
  if (!slides) return null

  const [swiper, updateSwiper] = useState(null)
  const [sliderPos, setSliderPos] = useState('isStart')

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  const params = {
    speed: 1000
  }

  const handleSlideChange = () => {
    if (swiper !== null) {
      const index = swiper.realIndex
      const length = swiper.slides.length - 1

      if (index === 0) {
        setSliderPos('isStart')
      }
      if (index === length) {
        setSliderPos('isEnd')
      }
      if (index !== length && index !== 0) {
        setSliderPos('')
      }
    }
  }

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', handleSlideChange)
    }
  })

  const renderSlide = slide => (
    <div className='slide' key={slide.imgAlt.asset.src}>
      <div className='container-fluid'>
        <div className='container'>
          <div className='row'>
            <Link to={slide.pageLink.slug.current} className='col-11 col-md-4 icon-link slide-head pt-2 mb-2 offset-md-7 offset-0'>
              <h2 className='text-link font-size-large'>
                {slide.headline}
              </h2>
              <Chevron className='ml-2' color='secondary' />
            </Link>
          </div>
        </div>
      </div>
      <div className='img-wrapper'>
        <div className='color-blend color' />
        <Img
          fluid={slide.imgAlt.asset.fluid}
          alt={slide.imgAlt.alt}
          backgroundColor='lightgray'
        />
      </div>
    </div>
  )

  return (
    <div className={classNames('hero-slider', sliderPos)}>
      <button className='prev pl-3 pl-md-4' onClick={goPrev} >
        <Chevron onClick={goPrev} size='small' rotation='right' />
      </button>
      <button className='next pr-3 pr-md-4' onClick={goNext}>
        <Chevron onClick={goPrev} size='small' />
      </button>
      <Swiper {...params} getSwiper={updateSwiper}>
        {
          slides.map(slide => (
            renderSlide(slide)
          ))
        }
      </Swiper>
    </div>
  )
}

export default HeroSlider
